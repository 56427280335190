export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: bigint
  BigIntLocationID: bigint
  Date: string
  DateTime: string
  Time: string
}

export type AddCostCenterQualificationResponse =
  | CostCenterQualificationAdded
  | ObjectDoesNotExist

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  countryName?: Maybe<Scalars['String']>
  countrySubdivisionCode?: Maybe<Scalars['String']>
  countrySubdivisionName?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  line2?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  stateOrProvinceAbbrev?: Maybe<Scalars['String']>
}

export type AddressQuery = {
  cityIcontains?: InputMaybe<Scalars['String']>
  cityIsNull?: InputMaybe<Scalars['Boolean']>
  distinctOn?: InputMaybe<Array<Scalars['String']>>
  shipmentCostCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
}

export type AssignDriverToShareRequestInput = {
  assignedDriverId: Scalars['Int']
  id: Scalars['Int']
}

export type AssignDriversToLegResponse =
  | DriverAssignedToLeg
  | InvalidDriverAssignment
  | InvalidLegStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | ObjectDoesNotExist
  | TmwValidationError

export type AssignTractorToLegResponse =
  | InvalidLegStatus
  | InvalidLegUpdateError
  | InvalidLoadStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | ObjectDoesNotExist
  | TmwValidationError
  | TractorAssignedToLeg

export type BackhaulCodeUpdated = {
  __typename?: 'BackhaulCodeUpdated'
  load?: Maybe<Load>
}

export enum BackhaulEligibleCode {
  Cclton = 'CCLTON',
  Cmpahr = 'CMPAHR',
  Cupref = 'CUPREF',
  Dnnasm = 'DNNASM',
  Drturn = 'DRTURN',
  Drvchc = 'DRVCHC',
  Fmdirc = 'FMDIRC',
  Hoscft = 'HOSCFT',
  Intbhl = 'INTBHL',
  Mcbrkd = 'MCBRKD',
  Other = 'OTHER',
  Shdist = 'SHDIST',
  Spceqp = 'SPCEQP',
  Unk = 'UNK',
  Vbhpln = 'VBHPLN',
  Wethr = 'WETHR',
  Yes = 'YES',
  Yeshh = 'YESHH',
  Yespb = 'YESPB',
}

export type BillTo = {
  __typename?: 'BillTo'
  companyTmwId: Scalars['String']
  id: Scalars['Int']
  name: Scalars['String']
}

export enum CdlClass {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum CdlEndorsement {
  DoubleTriple = 'DOUBLE_TRIPLE',
  Hazmat = 'HAZMAT',
  Tank = 'TANK',
  TankHazmat = 'TANK_HAZMAT',
}

export enum CdlRestriction {
  ClassBcVehicleOnly = 'CLASS_BC_VEHICLE_ONLY',
  ClassCVehicleOnly = 'CLASS_C_VEHICLE_ONLY',
  CmvFullAirBrake = 'CMV_FULL_AIR_BRAKE',
  FifthWheel = 'FIFTH_WHEEL',
  FullAirBrake = 'FULL_AIR_BRAKE',
  Manual = 'MANUAL',
  MedicalVariance = 'MEDICAL_VARIANCE',
}

export type CancelLoadResponse =
  | InvalidLoadStatus
  | InvalidRadiusVersion
  | LoadCancelled
  | ObjectDoesNotExist

export type Carrier = {
  __typename?: 'Carrier'
  baseTrackingLink?: Maybe<Scalars['String']>
  id: Scalars['Int']
  name: Scalars['String']
  scac: Scalars['String']
}

export type CostCenter = {
  __typename?: 'CostCenter'
  allowedStopEvents: Array<NamedStopEvent>
  code?: Maybe<Scalars['String']>
  customerName: Scalars['String']
  drivers: Array<Driver>
  fixedRoutes: Array<Route>
  id: Scalars['Int']
  name: Scalars['String']
  qualifications: Array<Qualification>
  radiusVersion: RadiusVersion
  stopTypes: Array<CostCenterStopType>
}

export type CostCenterFixedRoutesArgs = {
  query?: InputMaybe<RouteQuery>
}

export type CostCenterPaginatedResponse = {
  __typename?: 'CostCenterPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<CostCenter>
}

export type CostCenterQualification = {
  costCenterId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type CostCenterQualificationAdded = {
  __typename?: 'CostCenterQualificationAdded'
  costCenterId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type CostCenterQualificationRemoved = {
  __typename?: 'CostCenterQualificationRemoved'
  costCenterId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type CostCenterQuery = {
  codeEq?: InputMaybe<Scalars['String']>
  codeIn?: InputMaybe<Array<Scalars['String']>>
  codeIsNull?: InputMaybe<Scalars['Boolean']>
  customerId?: InputMaybe<Scalars['Int']>
  distanceToCostCenterIdLessThan?: InputMaybe<Array<Scalars['Int']>>
  driversQuery?: InputMaybe<DriverQuery>
  fuzzyMatch?: InputMaybe<Scalars['String']>
  geotabIdIn?: InputMaybe<Array<Scalars['String']>>
  geotabIdIsNull?: InputMaybe<Scalars['Boolean']>
  hasDriverManager?: InputMaybe<Scalars['Boolean']>
  hasPayrollDriver?: InputMaybe<Scalars['Boolean']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  isActiveEq?: InputMaybe<Scalars['Boolean']>
  isOnboardedEq?: InputMaybe<Scalars['Boolean']>
  isRealCostCenter?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  nameIcontains?: InputMaybe<Scalars['String']>
  nameIlike?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CostCenterStopType = {
  __typename?: 'CostCenterStopType'
  code: Scalars['String']
  name: Scalars['String']
  namedScosActions: Array<NamedStopEvent>
}

export type CostCenterToDriverDistance = {
  __typename?: 'CostCenterToDriverDistance'
  driversDistance: Array<DriverDistance>
  id: Scalars['Int']
}

export type CostCenterToOtherCostCenterDistance = {
  __typename?: 'CostCenterToOtherCostCenterDistance'
  id: Scalars['Int']
  otherCostCentersDistance: Array<OtherCostCenterDistance>
}

export type Customer = {
  __typename?: 'Customer'
  id: Scalars['Int']
  name: Scalars['String']
  type: CustomerType
}

export type CustomerPaginatedResponse = {
  __typename?: 'CustomerPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Customer>
}

export type CustomerQuery = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CustomerReferenceNumber = {
  __typename?: 'CustomerReferenceNumber'
  id: Scalars['Int']
  sourceSystem: SourceSystem
  type?: Maybe<Scalars['String']>
  value: Scalars['String']
}

export type CustomerReferenceNumberPaginatedResponse = {
  __typename?: 'CustomerReferenceNumberPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<CustomerReferenceNumber>
}

export type CustomerReferenceNumberQuery = {
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  distinctOn?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  loadCostCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  loadIdEq?: InputMaybe<Scalars['Int']>
  loadIdIn?: InputMaybe<Array<Scalars['Int']>>
  loadIdIsNull?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  shipmentCostCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  shipmentIdEq?: InputMaybe<Scalars['Int']>
  shipmentIdIn?: InputMaybe<Array<Scalars['Int']>>
  shipmentIdIsNull?: InputMaybe<Scalars['Boolean']>
  sourceSystemEq?: InputMaybe<SourceSystem>
  typeIn?: InputMaybe<Array<Scalars['String']>>
  typeIsNull?: InputMaybe<Scalars['Boolean']>
  valueIcontains?: InputMaybe<Scalars['String']>
  valueIn?: InputMaybe<Array<Scalars['String']>>
}

export enum CustomerType {
  Dts = 'DTS',
  Tm = 'TM',
}

export type CustomerUserCreate = {
  costCenterCodes: Array<Scalars['String']>
  customerId: Scalars['Int']
  email: Scalars['String']
  oktaId: Scalars['String']
}

export type CustomerUserCreateResponse =
  | CustomerUserCreated
  | InvalidEmailAddress
  | InvalidPassword
  | InvalidUserCredentials
  | ObjectDoesNotExist
  | OktaIdExists
  | UserAlreadyExists
  | UserHasCustomer

export type CustomerUserCreated = {
  __typename?: 'CustomerUserCreated'
  user: User
}

export type CustomerUserUpdate = {
  costCenterCodes: Array<Scalars['String']>
  userId: Scalars['Int']
}

export type CustomerUserUpdateResponse =
  | CustomerUserUpdated
  | InvalidCostCenterForCustomer
  | InvalidCustomerForUser

export type CustomerUserUpdated = {
  __typename?: 'CustomerUserUpdated'
  costCenterCodes: Array<Scalars['String']>
  userId: Scalars['Int']
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeleteQualificationResponse = ObjectDoesNotExist | QualificationDeleted

export type DeleteReferenceResponse =
  | InvalidLoadStatus
  | InvalidRadiusVersion
  | ObjectDoesNotExist
  | ReferenceDeleted

export type DenyDriverShareRequestInput = {
  id: Scalars['Int']
}

export type DispatchLoadToDriverResponse =
  | DispatchedToDrivers
  | InvalidLegUpdateError
  | InvalidLoadStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | NoDriverAssignedToLeg
  | ObjectDoesNotExist
  | TmwValidationError

export type DispatchedToDrivers = {
  __typename?: 'DispatchedToDrivers'
  load: Load
  tmwVersionId: Scalars['BigInt']
}

export type Document = {
  __typename?: 'Document'
  createdAt?: Maybe<Scalars['DateTime']>
  extension: DocumentExtension
  name: Scalars['String']
  tmwStopId?: Maybe<Scalars['Int']>
  type: DocumentType
  url: Scalars['String']
}

export enum DocumentExtension {
  Pdf = 'PDF',
}

export type DocumentList = {
  __typename?: 'DocumentList'
  documents: Array<Document>
}

export type DocumentResponse = DocumentList | UnableToRetrieveDocuments

export enum DocumentType {
  BillOfLading = 'BILL_OF_LADING',
  Cargo = 'CARGO',
  ExtraTripSlip = 'EXTRA_TRIP_SLIP',
  FineReceipt = 'FINE_RECEIPT',
  FuelReceipt = 'FUEL_RECEIPT',
  LateSlip = 'LATE_SLIP',
  LoadQuality = 'LOAD_QUALITY',
  LumperReceipt = 'LUMPER_RECEIPT',
  Mb = 'MB',
  Miscellaneous = 'MISCELLANEOUS',
  Osd = 'OSD',
  Other = 'OTHER',
  Photograph = 'PHOTOGRAPH',
  PortTicket = 'PORT_TICKET',
  PortTicketInOut = 'PORT_TICKET_IN_OUT',
  ProofOfDelivery = 'PROOF_OF_DELIVERY',
  Return = 'RETURN',
  TripSheet = 'TRIP_SHEET',
  WeightReceipt = 'WEIGHT_RECEIPT',
  WfxReceipt = 'WFX_RECEIPT',
}

export type Driver = {
  __typename?: 'Driver'
  authorizedToDrive: Scalars['Boolean']
  costCenters: Array<DriverCostCenter>
  distanceFromDomicileToCostCenter?: Maybe<Scalars['Float']>
  domicile?: Maybe<Location>
  email?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  fullname: Scalars['String']
  geotabId?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  license: DriversLicense
  manager?: Maybe<Worker>
  managerId?: Maybe<Scalars['Int']>
  phone?: Maybe<Scalars['String']>
  preferences: Array<Scalars['String']>
  previouslySharedToCostCenter: Scalars['Boolean']
  qualifications: Array<DriverQualification>
  tmwId?: Maybe<Scalars['String']>
}

export type DriverDistanceFromDomicileToCostCenterArgs = {
  costCenterId: Scalars['Int']
}

export type DriverPreviouslySharedToCostCenterArgs = {
  costCenterId: Scalars['Int']
}

export type DriverAssignedToLeg = {
  __typename?: 'DriverAssignedToLeg'
  load: Load
}

export enum DriverAvailabilityStatus {
  Available = 'AVAILABLE',
  Off = 'OFF',
  OnLoad = 'ON_LOAD',
  Unknown = 'UNKNOWN',
}

export type DriverCostCenter = {
  __typename?: 'DriverCostCenter'
  code?: Maybe<Scalars['String']>
  customerName: Scalars['String']
  id: Scalars['Int']
  isOperational: Scalars['Boolean']
  isPayroll: Scalars['Boolean']
  name: Scalars['String']
}

export type DriverDistance = {
  __typename?: 'DriverDistance'
  distance?: Maybe<Scalars['Float']>
  id: Scalars['Int']
}

export enum DriverDutyStatus {
  D = 'D',
  Off = 'OFF',
  On = 'ON',
  Sb = 'SB',
  Ym = 'YM',
}

export type DriverPaginatedResponse = {
  __typename?: 'DriverPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Driver>
}

export type DriverQualification = {
  __typename?: 'DriverQualification'
  completedAt?: Maybe<Scalars['Date']>
  driverId: Scalars['Int']
  expiresAt?: Maybe<Scalars['Date']>
  id: Scalars['Int']
  name: Scalars['String']
}

export type DriverQualificationAdd = {
  completedAt?: InputMaybe<Scalars['Date']>
  driverId: Scalars['Int']
  expiresAt?: InputMaybe<Scalars['Date']>
  qualificationId: Scalars['Int']
}

export type DriverQualificationRemoved = {
  __typename?: 'DriverQualificationRemoved'
  driverId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type DriverQualificationUpdate = {
  completedAt?: InputMaybe<Scalars['Date']>
  driverId: Scalars['Int']
  expiresAt?: InputMaybe<Scalars['Date']>
  qualificationId: Scalars['Int']
}

export type DriverQuery = {
  availableDuringFutureWindow?: InputMaybe<Array<Scalars['DateTime']>>
  availableDuringHaulRequestWindow?: InputMaybe<Array<Scalars['DateTime']>>
  cdlClassIn?: InputMaybe<Array<Scalars['String']>>
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  costCenterNameIn?: InputMaybe<Array<Scalars['String']>>
  distanceToCostCenterIdLessThan?: InputMaybe<Array<Scalars['Int']>>
  dutyStatusIn?: InputMaybe<Array<Scalars['String']>>
  employeeIdIn?: InputMaybe<Array<Scalars['String']>>
  employeeIdOrTmwIdEq?: InputMaybe<Array<Scalars['String']>>
  endorsementsContain?: InputMaybe<Array<Scalars['String']>>
  fuzzyMatch?: InputMaybe<Scalars['String']>
  geotabIdIn?: InputMaybe<Array<Scalars['String']>>
  geotabIdIsNull?: InputMaybe<Scalars['Boolean']>
  idEq?: InputMaybe<Scalars['Int']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  isActiveEq?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  notOnActiveLeg?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  onActiveLeg?: InputMaybe<Scalars['Boolean']>
  payrollCostCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  payrollCostCenterNameIn?: InputMaybe<Array<Scalars['String']>>
  qualificationIdsContain?: InputMaybe<Array<Scalars['Int']>>
  restrictionsContain?: InputMaybe<Array<Scalars['String']>>
  sort?: Array<DriverSortFields>
  sortByDriverDomicileDistanceToCostCenterId?: InputMaybe<Scalars['Int']>
  sortByDriverFullname?: InputMaybe<Scalars['Boolean']>
  tmwIdIn?: InputMaybe<Array<Scalars['String']>>
  tmwIdIsNull?: InputMaybe<Scalars['Boolean']>
}

export enum DriverRequestStatus {
  Complete = 'COMPLETE',
  Covered = 'COVERED',
  New = 'NEW',
  Removed = 'REMOVED',
  Unknown = 'UNKNOWN',
}

export type DriverShareRequest = {
  __typename?: 'DriverShareRequest'
  assignedDriver?: Maybe<Driver>
  cdlClass: Array<CdlClass>
  endTime: Scalars['DateTime']
  endorsements: Array<CdlEndorsement>
  id: Scalars['Int']
  note: Scalars['String']
  qualifications: Array<DriverShareRequestQualification>
  requester: User
  requesterCostCenter: CostCenter
  restrictions: Array<CdlRestriction>
  startTime: Scalars['DateTime']
  status: DriverRequestStatus
}

export type DriverShareRequestCreateInput = {
  cdlClass?: Array<CdlClass>
  endTime: Scalars['DateTime']
  endorsements?: Array<CdlEndorsement>
  note?: Scalars['String']
  qualificationIds: Array<Scalars['Int']>
  requesterCostCenterId: Scalars['Int']
  requesterId: Scalars['Int']
  restrictions?: Array<CdlRestriction>
  startTime: Scalars['DateTime']
  status: DriverRequestStatus
}

export type DriverShareRequestPaginatedResponse = {
  __typename?: 'DriverShareRequestPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<DriverShareRequest>
}

export type DriverShareRequestQualification = {
  __typename?: 'DriverShareRequestQualification'
  driverShareRequestId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type DriverShareRequestQuery = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  requesterCostCenterIdEq?: InputMaybe<Scalars['Int']>
  requesterIdEq?: InputMaybe<Scalars['Int']>
  sort?: Array<DriverShareRequestSortFields>
  statusEq?: InputMaybe<DriverRequestStatus>
}

export type DriverShareRequestResponse = DriverShareRequest

export enum DriverShareRequestSortFields {
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

export type DriverSimple = {
  __typename?: 'DriverSimple'
  authorizedToDrive: Scalars['Boolean']
  email?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  fullname: Scalars['String']
  geotabId?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
  tmwId?: Maybe<Scalars['String']>
}

export enum DriverSortFields {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
}

export type DriverStatus = {
  __typename?: 'DriverStatus'
  availability: DriverAvailabilityStatus
  breakSecRemaining: Scalars['Int']
  currentLeg?: Maybe<Leg>
  currentLoadReference?: Maybe<Scalars['String']>
  cycleSecRemaining: Scalars['Int']
  driverId: Scalars['Int']
  drivingSecRemaining: Scalars['Int']
  isShortHaulRuleset: Scalars['Boolean']
  nextLeg?: Maybe<Leg>
  overlappingLoadAssignmentsForDriver: Array<Load>
  shiftSecRemaining: Scalars['Int']
  status: DriverDutyStatus
  statusStartTime?: Maybe<Scalars['DateTime']>
  /** Timestamp in UTC driver is available to work after a 10 hr break. */
  tenHrResetPta?: Maybe<Scalars['String']>
  /** Timestamp in UTC driver is available to work after a 34 hr break. */
  thirtyFourHrResetPta?: Maybe<Scalars['String']>
  tractor?: Maybe<Tractor>
  tractorVin?: Maybe<Scalars['String']>
}

export type DriverStatusOverlappingLoadAssignmentsForDriverArgs = {
  legId: Scalars['Int']
}

export type DriverUpdate = {
  domicileId?: InputMaybe<Scalars['Int']>
  id: Scalars['Int']
  license?: InputMaybe<DriversLicenseUpdate>
  preferences?: InputMaybe<Array<Scalars['String']>>
}

export type DriverWithStatus = {
  __typename?: 'DriverWithStatus'
  authorizedToDrive: Scalars['Boolean']
  costCenters: Array<DriverCostCenter>
  currentStatus: DriverStatus
  domicile?: Maybe<Location>
  email?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  fullname: Scalars['String']
  geotabId?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  license: DriversLicense
  phone?: Maybe<Scalars['String']>
  preferences: Array<Scalars['String']>
  qualifications: Array<DriverQualification>
  tmwId?: Maybe<Scalars['String']>
}

export type DriverWithStatusPaginatedResponse = {
  __typename?: 'DriverWithStatusPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<DriverWithStatus>
}

export type DriversLicense = {
  __typename?: 'DriversLicense'
  cdlClass?: Maybe<CdlClass>
  endorsements: Array<CdlEndorsement>
  expiresAt?: Maybe<Scalars['Date']>
  medcardExpiration?: Maybe<Scalars['Date']>
  restrictions: Array<CdlRestriction>
}

export type DriversLicenseUpdate = {
  cdlClass?: InputMaybe<CdlClass>
  endorsements?: InputMaybe<Array<CdlEndorsement>>
  expiresAt?: InputMaybe<Scalars['Date']>
  medcardExpiration?: InputMaybe<Scalars['Date']>
  restrictions?: InputMaybe<Array<CdlRestriction>>
}

export enum EquipmentType {
  Bobtail = 'BOBTAIL',
  Car = 'CAR',
  CargoVan = 'CARGO_VAN',
  Chassis = 'CHASSIS',
  Conestogal = 'CONESTOGAL',
  ContainerRefrigerated = 'CONTAINER_REFRIGERATED',
  ContainerStandard = 'CONTAINER_STANDARD',
  CsxRrOriginCore = 'CSX_RR_ORIGIN_CORE',
  DoubleAxle = 'DOUBLE_AXLE',
  DoubleDropLowBoy = 'DOUBLE_DROP_LOW_BOY',
  DoubleDropTriAxleExtendedTrailer = 'DOUBLE_DROP_TRI_AXLE_EXTENDED_TRAILER',
  DryVanPaperspec = 'DRY_VAN_PAPERSPEC',
  DumpEnd = 'DUMP_END',
  DumpSide = 'DUMP_SIDE',
  Flatbed = 'FLATBED',
  FlatbedCurtainSide = 'FLATBED_CURTAIN_SIDE',
  FoodTrailerFrozen = 'FOOD_TRAILER_FROZEN',
  Forklift = 'FORKLIFT',
  GravelDumper = 'GRAVEL_DUMPER',
  HarleyDedicatedDv = 'HARLEY_DEDICATED_DV',
  HighCube = 'HIGH_CUBE',
  Hopper = 'HOPPER',
  HotOilTruck = 'HOT_OIL_TRUCK',
  HydroExcavationTruck = 'HYDRO_EXCAVATION_TRUCK',
  Intermodal = 'INTERMODAL',
  IntermodalReefer = 'INTERMODAL_REEFER',
  IntermodalVan = 'INTERMODAL_VAN',
  LessThanTruckLoad = 'LESS_THAN_TRUCK_LOAD',
  Liftgate = 'LIFTGATE',
  LtlSyngenta = 'LTL_SYNGENTA',
  MiniFloat = 'MINI_FLOAT',
  Moffett = 'MOFFETT',
  NsRrOriginCore = 'NS_RR_ORIGIN_CORE',
  Other = 'OTHER',
  PickupTruck = 'PICKUP_TRUCK',
  Pneumatic = 'PNEUMATIC',
  PumpTruck = 'PUMP_TRUCK',
  QuadAxleTrailer = 'QUAD_AXLE_TRAILER',
  Railcar = 'RAILCAR',
  RailcarHighCube = 'RAILCAR_HIGH_CUBE',
  Refrigerated = 'REFRIGERATED',
  RemovableGooseNeck = 'REMOVABLE_GOOSE_NECK',
  RollOnRollOffShips = 'ROLL_ON_ROLL_OFF_SHIPS',
  ShortSemiSyngenta = 'SHORT_SEMI_SYNGENTA',
  SprinterVan = 'SPRINTER_VAN',
  StepDeck = 'STEP_DECK',
  StraightTruck = 'STRAIGHT_TRUCK',
  TandemSyngenta = 'TANDEM_SYNGENTA',
  Tanker = 'TANKER',
  TankerChemical = 'TANKER_CHEMICAL',
  TriAxle = 'TRI_AXLE',
  TruckVan = 'TRUCK_VAN',
  UpRrOriginCore = 'UP_RR_ORIGIN_CORE',
  VacuumTruck = 'VACUUM_TRUCK',
  VanDry = 'VAN_DRY',
  VanSyngenta = 'VAN_SYNGENTA',
  WinchTruck = 'WINCH_TRUCK',
}

export type ErrorType = {
  message: Scalars['String']
}

export type GpsPoint = {
  __typename?: 'GPSPoint'
  lat: Scalars['Float']
  lon: Scalars['Float']
}

export type GpsPointInput = {
  lat: Scalars['Float']
  lon: Scalars['Float']
}

export type GpsPolygon = {
  __typename?: 'GPSPolygon'
  bounds: Array<GpsPoint>
  id: Scalars['Int']
}

export type GpsPolygonBaseInput = {
  bounds: Array<GpsPointInput>
}

export type GpsPolygonCreate = {
  bounds: Array<GpsPointInput>
}

export type GpsPolygonUpdate = {
  bounds?: InputMaybe<Array<GpsPointInput>>
  id: Scalars['Int']
}

export type Georoute = {
  __typename?: 'Georoute'
  actualRoute: Array<GpsPoint>
  expectedRoute: Array<GpsPoint>
}

export type GetTrackingInfoResponse =
  | InvalidTrackingLink
  | TrackingInfo
  | TrackingLinkInternalError

export enum HomePage {
  DriverVisibility = 'DRIVER_VISIBILITY',
  Loadboard = 'LOADBOARD',
}

export type InvalidCostCenterForCustomer = ErrorType & {
  __typename?: 'InvalidCostCenterForCustomer'
  message: Scalars['String']
}

export type InvalidCustomerForUser = ErrorType & {
  __typename?: 'InvalidCustomerForUser'
  message: Scalars['String']
}

export type InvalidDriverAssignment = ErrorType & {
  __typename?: 'InvalidDriverAssignment'
  message: Scalars['String']
}

export type InvalidEmailAddress = ErrorType & {
  __typename?: 'InvalidEmailAddress'
  message: Scalars['String']
}

export type InvalidLegStatus = ErrorType & {
  __typename?: 'InvalidLegStatus'
  message: Scalars['String']
}

export type InvalidLegUpdateError = ErrorType & {
  __typename?: 'InvalidLegUpdateError'
  message: Scalars['String']
}

export type InvalidLoadStatus = ErrorType & {
  __typename?: 'InvalidLoadStatus'
  message: Scalars['String']
}

export type InvalidOdometer = ErrorType & {
  __typename?: 'InvalidOdometer'
  message: Scalars['String']
}

export type InvalidOperatingHoursError = ErrorType & {
  __typename?: 'InvalidOperatingHoursError'
  message: Scalars['String']
}

export type InvalidPassword = ErrorType & {
  __typename?: 'InvalidPassword'
  message: Scalars['String']
}

export type InvalidRadiusVersion = ErrorType & {
  __typename?: 'InvalidRadiusVersion'
  message: Scalars['String']
}

export type InvalidStopAppointmentEnd = ErrorType & {
  __typename?: 'InvalidStopAppointmentEnd'
  message: Scalars['String']
}

export type InvalidStopAppointmentStart = ErrorType & {
  __typename?: 'InvalidStopAppointmentStart'
  message: Scalars['String']
}

export type InvalidStopAppointmentTime = ErrorType & {
  __typename?: 'InvalidStopAppointmentTime'
  message: Scalars['String']
}

export type InvalidStopDateTime = ErrorType & {
  __typename?: 'InvalidStopDateTime'
  message: Scalars['String']
}

export type InvalidStopDelete = ErrorType & {
  __typename?: 'InvalidStopDelete'
  message: Scalars['String']
}

export type InvalidStopEvent = ErrorType & {
  __typename?: 'InvalidStopEvent'
  message: Scalars['String']
}

export type InvalidStopIdx = ErrorType & {
  __typename?: 'InvalidStopIdx'
  message: Scalars['String']
}

export type InvalidStopLocation = ErrorType & {
  __typename?: 'InvalidStopLocation'
  message: Scalars['String']
}

export type InvalidStopReorder = ErrorType & {
  __typename?: 'InvalidStopReorder'
  message: Scalars['String']
}

export type InvalidStopStatus = ErrorType & {
  __typename?: 'InvalidStopStatus'
  message: Scalars['String']
}

export type InvalidStopUpdateField = ErrorType & {
  __typename?: 'InvalidStopUpdateField'
  message: Scalars['String']
}

export type InvalidTmwVersionIdAnton = ErrorType & {
  __typename?: 'InvalidTMWVersionIDAnton'
  message: Scalars['String']
}

export type InvalidTmwVersionIdClient = ErrorType & {
  __typename?: 'InvalidTMWVersionIDClient'
  message: Scalars['String']
}

export type InvalidTmwVersionIdScos = ErrorType & {
  __typename?: 'InvalidTMWVersionIDScos'
  message: Scalars['String']
}

export type InvalidTrackingLink = ErrorType & {
  __typename?: 'InvalidTrackingLink'
  message: Scalars['String']
}

export type InvalidUserCredentials = ErrorType & {
  __typename?: 'InvalidUserCredentials'
  message: Scalars['String']
}

export type Leg = {
  __typename?: 'Leg'
  backhaulEligibleCode?: Maybe<BackhaulEligibleCode>
  costCenter?: Maybe<CostCenter>
  destination?: Maybe<Address>
  driverOdometerEnd?: Maybe<Scalars['Int']>
  driverOdometerStart?: Maybe<Scalars['Int']>
  endTime?: Maybe<Scalars['DateTime']>
  estimatedTripTime: Scalars['Int']
  georoute: Georoute
  id: Scalars['Int']
  idx: Scalars['Int']
  loadId: Scalars['Int']
  loadReference?: Maybe<Scalars['String']>
  origin?: Maybe<Address>
  plannedEndTime?: Maybe<Scalars['DateTime']>
  plannedMiles?: Maybe<Scalars['Int']>
  plannedMilesPcmiler: Scalars['Float']
  plannedStartTime?: Maybe<Scalars['DateTime']>
  primaryDriver?: Maybe<DriverSimple>
  primaryDriverId?: Maybe<Scalars['Int']>
  routeCode?: Maybe<Scalars['String']>
  routeId?: Maybe<Scalars['Int']>
  secondaryDriver?: Maybe<DriverSimple>
  secondaryDriverId?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['DateTime']>
  status: LegStatus
  stops: Array<Stop>
  tmwId: Scalars['Int']
  tractor?: Maybe<Tractor>
  tractorId?: Maybe<Scalars['Int']>
}

export type LegPlannedStartTimeResponse =
  | InvalidLegStatus
  | InvalidLegUpdateError
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | LegPlannedStartTimeUpdated
  | ObjectDoesNotExist
  | TmwValidationError

export type LegPlannedStartTimeUpdated = {
  __typename?: 'LegPlannedStartTimeUpdated'
  load: Load
  tmwVersionId: Scalars['BigInt']
}

export type LegSplit = {
  legId: Scalars['Int']
  locationId?: InputMaybe<Scalars['BigInt']>
  /** Index of the stop before which to split the leg */
  stopIdx: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
}

export enum LegStatus {
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Dispatched = 'DISPATCHED',
  Planned = 'PLANNED',
  Started = 'STARTED',
  Unknown = 'UNKNOWN',
}

export type Load = {
  __typename?: 'Load'
  autoCompleteScheduledTime?: Maybe<Scalars['DateTime']>
  billTo?: Maybe<BillTo>
  billToId: Scalars['Int']
  costCenter?: Maybe<CostCenter>
  customerReferenceNumbers: Array<CustomerReferenceNumber>
  documents: DocumentResponse
  endTime?: Maybe<Scalars['DateTime']>
  equipmentSize?: Maybe<Scalars['String']>
  equipmentType?: Maybe<EquipmentType>
  id: Scalars['Int']
  initialEstimatedTime: Scalars['Int']
  legs: Array<Leg>
  notes?: Maybe<Scalars['String']>
  proReference?: Maybe<CustomerReferenceNumber>
  reference?: Maybe<Scalars['String']>
  route?: Maybe<Route>
  routeId?: Maybe<Scalars['Int']>
  source: LoadSource
  startTime?: Maybe<Scalars['DateTime']>
  status: LoadStatus
  tmwVersionId: Scalars['BigInt']
  transitMode?: Maybe<TransitMode>
}

export type LoadCancelled = {
  __typename?: 'LoadCancelled'
  load: Load
}

export type LoadCreate = {
  costCenterId: Scalars['Int']
  plannedStartTime: Scalars['DateTime']
  routeId: Scalars['Int']
}

export type LoadCreateResponse = LoadCreated | ObjectDoesNotExist | TmwValidationError

export type LoadCreated = {
  __typename?: 'LoadCreated'
  load: Load
  loadId: Scalars['Int']
  loadReference: Scalars['String']
}

export type LoadDeleted = {
  __typename?: 'LoadDeleted'
  id: Scalars['Int']
}

export type LoadMarkedCompleted = {
  __typename?: 'LoadMarkedCompleted'
  load: Load
}

export type LoadPaginatedResponse = {
  __typename?: 'LoadPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Load>
}

export type LoadQuery = {
  costCenterIdEq?: InputMaybe<Scalars['Int']>
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  costCenterIdIsNull?: InputMaybe<Scalars['Boolean']>
  costCenterRadiusVersionEq?: InputMaybe<RadiusVersion>
  customerReferenceNumberEq?: InputMaybe<Scalars['String']>
  driverIdEq?: InputMaybe<Scalars['Int']>
  driverIdIn?: InputMaybe<Array<Scalars['Int']>>
  idEq?: InputMaybe<Scalars['Int']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  limit?: InputMaybe<Scalars['Int']>
  locationIdIn?: InputMaybe<Array<Scalars['BigIntLocationID']>>
  offset?: InputMaybe<Scalars['Int']>
  plannedStartDateRange?: InputMaybe<Array<Scalars['Date']>>
  plannedStartTimeDateRange?: InputMaybe<Array<Scalars['DateTime']>>
  referenceEq?: InputMaybe<Scalars['String']>
  referenceIcontains?: InputMaybe<Scalars['String']>
  referenceIsNull?: InputMaybe<Scalars['Boolean']>
  routeIdEq?: InputMaybe<Scalars['Int']>
  routeIdIn?: InputMaybe<Array<Scalars['Int']>>
  routeIdIsNull?: InputMaybe<Scalars['Boolean']>
  shipmentCodeEqAndCostCenterIdEq?: InputMaybe<Array<Scalars['String']>>
  sort?: Array<LoadSortFields>
  sortByPlannedStartTime?: InputMaybe<SortInput>
  sourceIdEq?: InputMaybe<Scalars['String']>
  sourceIdIsNull?: InputMaybe<Scalars['Boolean']>
  sourceSystemEq?: InputMaybe<SourceSystem>
  sourceSystemIsNull?: InputMaybe<Scalars['Boolean']>
  statusEq?: InputMaybe<LoadStatus>
  statusIn?: InputMaybe<Array<LoadStatus>>
  tmwIdEq?: InputMaybe<Scalars['Int']>
  tmwIdIsNull?: InputMaybe<Scalars['Boolean']>
}

export enum LoadSortFields {
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

export enum LoadSource {
  Radius = 'RADIUS',
  Scos = 'SCOS',
  Tm = 'TM',
  Unknown = 'UNKNOWN',
}

export enum LoadStatus {
  Accepted = 'ACCEPTED',
  AcceptPending = 'ACCEPT_PENDING',
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  DeclinePending = 'DECLINE_PENDING',
  Dispatched = 'DISPATCHED',
  Imported = 'IMPORTED',
  InAudit = 'IN_AUDIT',
  OptimizationPending = 'OPTIMIZATION_PENDING',
  OptimizationReady = 'OPTIMIZATION_READY',
  Planned = 'PLANNED',
  Started = 'STARTED',
  Suspended = 'SUSPENDED',
  Tendered = 'TENDERED',
  TenderOffered = 'TENDER_OFFERED',
  TenderRejected = 'TENDER_REJECTED',
  Unknown = 'UNKNOWN',
}

export type LoadSubscription = Load | LoadDeleted

export type Location = {
  __typename?: 'Location'
  address?: Maybe<Address>
  contacts: Array<LocationContact>
  defaultStopType?: Maybe<Scalars['String']>
  id: Scalars['BigIntLocationID']
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  observesDst?: Maybe<Scalars['Boolean']>
  operatingHours: Array<OperatingHours>
  point?: Maybe<GpsPoint>
  polygons: Array<GpsPolygon>
  radius: Scalars['Int']
  timeZoneCode: Scalars['String']
  timeZoneName: Scalars['String']
}

export enum LocationActionType {
  Drop = 'DROP',
  DropAndHook = 'DROP_AND_HOOK',
  Hook = 'HOOK',
  Load = 'LOAD',
  None = 'NONE',
  Unknown = 'UNKNOWN',
  Unload = 'UNLOAD',
}

export type LocationContact = {
  __typename?: 'LocationContact'
  email: Scalars['String']
  fullname: Scalars['String']
  id: Scalars['Int']
  isPrimary: Scalars['Boolean']
  phoneNumber?: Maybe<Scalars['String']>
}

export type LocationPaginatedResponse = {
  __typename?: 'LocationPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Location>
}

export type LocationQuery = {
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  fuzzyMatch?: InputMaybe<Scalars['String']>
  idIn?: InputMaybe<Array<Scalars['BigIntLocationID']>>
  limit?: InputMaybe<Scalars['Int']>
  nameIcontains?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameIsNull?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  polygonIdIn?: InputMaybe<Array<Scalars['Int']>>
  sort?: Array<LocationSortFields>
  sourceIdEq?: InputMaybe<Scalars['String']>
  sourceIdIn?: InputMaybe<Array<Scalars['String']>>
  sourceIdIsNull?: InputMaybe<Scalars['Boolean']>
  sourceSystemEq?: InputMaybe<SourceSystem>
  sourceSystemIn?: InputMaybe<Array<SourceSystem>>
  sourceSystemIsNull?: InputMaybe<Scalars['Boolean']>
  tmwIdEq?: InputMaybe<Scalars['String']>
  tmwIdIn?: InputMaybe<Array<Scalars['String']>>
  tmwIdIsNull?: InputMaybe<Scalars['Boolean']>
}

export type LocationResponse =
  | InvalidOperatingHoursError
  | Location
  | ObjectDoesNotExist
  | OverlappingPolygons

export enum LocationSortFields {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type LocationUpdate = {
  id: Scalars['BigIntLocationID']
  name?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  operatingHours?: InputMaybe<Array<OperatingHoursCreate>>
  point?: InputMaybe<GpsPointInput>
  polygonCreates?: InputMaybe<Array<GpsPolygonCreate>>
  polygonUpdates?: InputMaybe<Array<GpsPolygonUpdate>>
  radius?: InputMaybe<Scalars['Int']>
}

export type MarkLoadCompletedResponse =
  | InvalidLoadStatus
  | InvalidRadiusVersion
  | LoadMarkedCompleted
  | ObjectDoesNotExist

export type MarkedRead = {
  __typename?: 'MarkedRead'
  notificationIds: Array<Scalars['Int']>
  readAt: Scalars['DateTime']
}

export type MileageType = {
  __typename?: 'MileageType'
  code: Scalars['String']
  name: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Add a qualification as a requirement for a cost center */
  addCostCenterQualification: AddCostCenterQualificationResponse
  addDriverQualification: DriverQualification
  addStop: StopAddResponse
  assignDriverToShareRequest: DriverShareRequestResponse
  assignDriversToLeg: AssignDriversToLegResponse
  assignTractorToLeg: AssignTractorToLegResponse
  cancelLoad: CancelLoadResponse
  createCustomerUser: CustomerUserCreateResponse
  createDriverShareRequest: DriverShareRequestResponse
  createLoad: LoadCreateResponse
  /** Delete a qualification. */
  deleteQualification: DeleteQualificationResponse
  deleteReference: DeleteReferenceResponse
  deleteStop: StopDeleteResponse
  denyDriverShareRequest: DriverShareRequestResponse
  dispatchLoad: DispatchLoadToDriverResponse
  duplicateStop: StopAddResponse
  /** Create a new qualification, or return an existing match. */
  getOrCreateQualification: QualificationResponse
  login: UserResponse
  logout: User
  markLoadCompleted: MarkLoadCompletedResponse
  markNotificationsAsRead: MarkedRead
  /** Remove a qualification as a requirement for a cost center */
  removeCostCenterQualification: RemoveCostCenterQualificationResponse
  removeDriverQualification: RemoveDriverQualificationResponse
  reorderStop: StopReorderResponse
  /** Set qualifications for a cost center */
  setQualificationsForCostCenter: CostCenter
  splitLeg: SplitLegResponse
  updateBackhaulCode: UpdateBackhaulCodeResponse
  updateCustomerUser: CustomerUserUpdateResponse
  updateDriver: Driver
  updateDriverQualification: DriverQualification
  updateLegPlannedStartTime: LegPlannedStartTimeResponse
  updateLocation: LocationResponse
  /** Update a qualification. */
  updateQualification: QualificationResponse
  updateStop: StopUpdateResponse
  updateUserSettings: UserSettingsResponse
}

export type MutationAddCostCenterQualificationArgs = {
  input: CostCenterQualification
}

export type MutationAddDriverQualificationArgs = {
  input: DriverQualificationAdd
}

export type MutationAddStopArgs = {
  input: StopAddRequest
}

export type MutationAssignDriverToShareRequestArgs = {
  input: AssignDriverToShareRequestInput
}

export type MutationAssignDriversToLegArgs = {
  legId: Scalars['Int']
  primaryDriverId?: InputMaybe<Scalars['Int']>
  secondaryDriverId?: InputMaybe<Scalars['Int']>
  tmwVersionId: Scalars['BigInt']
}

export type MutationAssignTractorToLegArgs = {
  legId: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
  tractorId?: InputMaybe<Scalars['Int']>
}

export type MutationCancelLoadArgs = {
  loadId: Scalars['Int']
}

export type MutationCreateCustomerUserArgs = {
  input: CustomerUserCreate
}

export type MutationCreateDriverShareRequestArgs = {
  input: DriverShareRequestCreateInput
}

export type MutationCreateLoadArgs = {
  input: LoadCreate
}

export type MutationDeleteQualificationArgs = {
  id: Scalars['Int']
}

export type MutationDeleteReferenceArgs = {
  loadId: Scalars['Int']
  referenceId: Scalars['Int']
}

export type MutationDeleteStopArgs = {
  stopId: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
}

export type MutationDenyDriverShareRequestArgs = {
  input: DenyDriverShareRequestInput
}

export type MutationDispatchLoadArgs = {
  loadId: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
}

export type MutationDuplicateStopArgs = {
  input: StopDuplicateRequest
}

export type MutationGetOrCreateQualificationArgs = {
  input: QualificationCreate
}

export type MutationLoginArgs = {
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationMarkLoadCompletedArgs = {
  loadId: Scalars['Int']
}

export type MutationMarkNotificationsAsReadArgs = {
  notificationIds: Array<Scalars['Int']>
}

export type MutationRemoveCostCenterQualificationArgs = {
  input: CostCenterQualification
}

export type MutationRemoveDriverQualificationArgs = {
  driverId: Scalars['Int']
  qualificationId: Scalars['Int']
}

export type MutationReorderStopArgs = {
  input: StopReorder
}

export type MutationSetQualificationsForCostCenterArgs = {
  costCenterId: Scalars['Int']
  qualificationNames: Array<Scalars['String']>
}

export type MutationSplitLegArgs = {
  input: LegSplit
}

export type MutationUpdateBackhaulCodeArgs = {
  backhaulCode: Scalars['String']
  loadId: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
}

export type MutationUpdateCustomerUserArgs = {
  input: CustomerUserUpdate
}

export type MutationUpdateDriverArgs = {
  input: DriverUpdate
}

export type MutationUpdateDriverQualificationArgs = {
  input: DriverQualificationUpdate
}

export type MutationUpdateLegPlannedStartTimeArgs = {
  legId: Scalars['Int']
  plannedStartTime: Scalars['DateTime']
  tmwVersionId: Scalars['BigInt']
}

export type MutationUpdateLocationArgs = {
  input: LocationUpdate
}

export type MutationUpdateQualificationArgs = {
  input: QualificationUpdate
}

export type MutationUpdateStopArgs = {
  input: StopUpdate
}

export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsUpdate
}

export type NamedStopEvent = {
  __typename?: 'NamedStopEvent'
  name?: Maybe<Scalars['String']>
  scosAction: ScosStopAction
}

export type NoCostCenterAssignedToLoad = ErrorType & {
  __typename?: 'NoCostCenterAssignedToLoad'
  message: Scalars['String']
}

export type NoDriverAssignedToLeg = ErrorType & {
  __typename?: 'NoDriverAssignedToLeg'
  message: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  driver?: Maybe<DriverSimple>
  driverId?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  legIdx?: Maybe<Scalars['Int']>
  load?: Maybe<Load>
  loadId?: Maybe<Scalars['Int']>
  readAt?: Maybe<Scalars['DateTime']>
  stopIdx?: Maybe<Scalars['Int']>
  tier: RiskTier
  timestamp: Scalars['DateTime']
  type: NotificationType
}

export enum NotificationType {
  Detention = 'DETENTION',
  HosViolation = 'HOS_VIOLATION',
  LegNotStarted = 'LEG_NOT_STARTED',
  OneHourDetention = 'ONE_HOUR_DETENTION',
}

export type ObjectDoesNotExist = ErrorType & {
  __typename?: 'ObjectDoesNotExist'
  message: Scalars['String']
}

export type OktaIdExists = ErrorType & {
  __typename?: 'OktaIDExists'
  message: Scalars['String']
}

export type OperatingHours = {
  __typename?: 'OperatingHours'
  endTime: TimeOfWeek
  startTime: TimeOfWeek
}

export type OperatingHoursCreate = {
  endTime: TimeOfWeekInput
  startTime: TimeOfWeekInput
}

export type OtherCostCenterDistance = {
  __typename?: 'OtherCostCenterDistance'
  distance: Scalars['Float']
  id: Scalars['Int']
}

export type OverlappingPolygons = ErrorType & {
  __typename?: 'OverlappingPolygons'
  message: Scalars['String']
}

export type PageMeta = {
  __typename?: 'PageMeta'
  /** The current page number in the dataset. */
  page: Scalars['Int']
  /** The total number of pages in the dataset. */
  pages: Scalars['Int']
  /** The total number of items in the dataset. */
  total: Scalars['Int']
}

export type Qualification = {
  __typename?: 'Qualification'
  id: Scalars['Int']
  name: Scalars['String']
}

export type QualificationCreate = {
  name: Scalars['String']
}

export type QualificationDeleted = {
  __typename?: 'QualificationDeleted'
  id: Scalars['Int']
}

export type QualificationQuery = {
  idEq?: InputMaybe<Scalars['Int']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  nameEq?: InputMaybe<Scalars['String']>
  nameIcontains?: InputMaybe<Scalars['String']>
  nameIsNull?: InputMaybe<Scalars['Boolean']>
}

export type QualificationResponse = ObjectDoesNotExist | Qualification

export type QualificationUpdate = {
  id: Scalars['Int']
  name: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  currentUser?: Maybe<User>
  /** Get a list of active cost centers. */
  getAllActiveCostCenters: Array<CostCenter>
  /** Get allowed mileage types for a given cost center. */
  getCostCenterMileageTypes: Array<MileageType>
  /** Get a list of cost centers. */
  getCostCenters: CostCenterPaginatedResponse
  /** Search for reference numbers by substring. */
  getCustomerReferenceNumbers: CustomerReferenceNumberPaginatedResponse
  /** Get customer accounts */
  getCustomers: CustomerPaginatedResponse
  /** Get distance from cost center to a list of drivers' domiciles */
  getDistanceFromCostCenterToDriversDomicile: CostCenterToDriverDistance
  /** Get distance to a list of cost centers */
  getDistanceFromCostCenterToOtherCostCenters: CostCenterToOtherCostCenterDistance
  /** Get a list of Driver Share Request Queries. */
  getDriverShareRequests: DriverShareRequestPaginatedResponse
  /** Get a list of drivers. */
  getDrivers: DriverPaginatedResponse
  /** Get a list of drivers with status. */
  getDriversWithStatus: DriverWithStatusPaginatedResponse
  /** Get a load. */
  getLoad: Load
  /** Search for load references by substring. */
  getLoadReferences: CustomerReferenceNumberPaginatedResponse
  /** Get a list of loads. */
  getLoads: LoadPaginatedResponse
  /** Get location by location id */
  getLocation?: Maybe<Location>
  /** Get list of locations */
  getLocations: LocationPaginatedResponse
  /** Get locations in given bounds */
  getLocationsInBounds: LocationPaginatedResponse
  getNotificationsForUser: Array<Notification>
  /** Get all qualifications. */
  getQualifications: Array<Qualification>
  /** Get a list of routes. */
  getRoutes: RoutePaginatedResponse
  /** Get a list of shipment addresses */
  getShipmentAddresses: Array<Address>
  /** Search for shipment references by substring. */
  getShipmentReferences: CustomerReferenceNumberPaginatedResponse
  /** Search for shipments by substring. */
  getShipments: ShipmentPaginatedResponse
  /** get tracking info for stop */
  getTrackingInfo?: Maybe<GetTrackingInfoResponse>
  /** Get a tracking link for a given stop for a load */
  getTrackingLink: TrackingLink
  /** Get a list of tractors. */
  getTractors: TractorPaginatedResponse
  getUser?: Maybe<User>
  /** Get a list of users. */
  getUsers: UserPaginatedResponse
  /** validate a given tracking link */
  validateTrackingLink: ValidateTrackingLinkResponse
}

export type QueryGetCostCenterMileageTypesArgs = {
  costCenterId: Scalars['Int']
}

export type QueryGetCostCentersArgs = {
  query: CostCenterQuery
}

export type QueryGetCustomerReferenceNumbersArgs = {
  query: CustomerReferenceNumberQuery
}

export type QueryGetCustomersArgs = {
  query: CustomerQuery
}

export type QueryGetDistanceFromCostCenterToDriversDomicileArgs = {
  costCenterId: Scalars['Int']
  driverIds: Array<Scalars['Int']>
}

export type QueryGetDistanceFromCostCenterToOtherCostCentersArgs = {
  costCenterId: Scalars['Int']
  otherCostCenterIds: Array<Scalars['Int']>
}

export type QueryGetDriverShareRequestsArgs = {
  filter?: InputMaybe<DriverShareRequestQuery>
}

export type QueryGetDriversArgs = {
  query: DriverQuery
}

export type QueryGetDriversWithStatusArgs = {
  query: DriverQuery
}

export type QueryGetLoadArgs = {
  loadId: Scalars['Int']
}

export type QueryGetLoadReferencesArgs = {
  query: CustomerReferenceNumberQuery
}

export type QueryGetLoadsArgs = {
  query: LoadQuery
}

export type QueryGetLocationArgs = {
  id: Scalars['BigIntLocationID']
}

export type QueryGetLocationsArgs = {
  query: LocationQuery
}

export type QueryGetLocationsInBoundsArgs = {
  polygon: GpsPolygonBaseInput
}

export type QueryGetNotificationsForUserArgs = {
  timeFloor?: InputMaybe<Scalars['DateTime']>
}

export type QueryGetQualificationsArgs = {
  query: QualificationQuery
}

export type QueryGetRoutesArgs = {
  query: RouteQuery
}

export type QueryGetShipmentAddressesArgs = {
  query: AddressQuery
}

export type QueryGetShipmentReferencesArgs = {
  query: CustomerReferenceNumberQuery
}

export type QueryGetShipmentsArgs = {
  query: ShipmentQuery
}

export type QueryGetTrackingInfoArgs = {
  input: TrackingLinkInput
}

export type QueryGetTrackingLinkArgs = {
  stopId: Scalars['Int']
}

export type QueryGetTractorsArgs = {
  query: TractorQuery
}

export type QueryGetUserArgs = {
  id: Scalars['Int']
}

export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserQuery>
}

export type QueryValidateTrackingLinkArgs = {
  input: TrackingLinkInput
}

export enum RadiusVersion {
  V2 = 'V2',
  V3 = 'V3',
}

export type ReferenceDeleted = {
  __typename?: 'ReferenceDeleted'
  load: Load
}

export type RemoveCostCenterQualificationResponse =
  | CostCenterQualificationRemoved
  | ObjectDoesNotExist

export type RemoveDriverQualificationResponse =
  | DriverQualificationRemoved
  | ObjectDoesNotExist

export enum RiskTier {
  Critical = 'CRITICAL',
  Resolved = 'RESOLVED',
  Risk = 'RISK',
}

export type Route = {
  __typename?: 'Route'
  code: Scalars['String']
  id: Scalars['Int']
  name: Scalars['String']
  stops: Array<RouteStop>
}

export type RoutePaginatedResponse = {
  __typename?: 'RoutePaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Route>
}

export type RouteQuery = {
  codeEq?: InputMaybe<Scalars['String']>
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  endDateRange?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  excludeRetired?: InputMaybe<Scalars['Boolean']>
  fuzzyMatch?: InputMaybe<Scalars['String']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  limit?: InputMaybe<Scalars['Int']>
  nameIcontains?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: Array<RouteSortFields>
  startDateRange?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
}

export enum RouteSortFields {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type RouteStop = {
  __typename?: 'RouteStop'
  appointmentEnd?: Maybe<Scalars['DateTime']>
  appointmentStart?: Maybe<Scalars['DateTime']>
  id: Scalars['Int']
  idx: Scalars['Int']
  location?: Maybe<Location>
  mileageType: Scalars['String']
  scosAction: ScosStopAction
  stopType: Scalars['String']
}

export enum ScosStopAction {
  Bbt = 'BBT',
  Bcst = 'BCST',
  Bmt = 'BMT',
  Cdrp = 'CDRP',
  Chk = 'CHK',
  Cpup = 'CPUP',
  Crt = 'CRT',
  Ctr = 'CTR',
  D = 'D',
  Dep = 'DEP',
  Dhl = 'DHL',
  Div = 'DIV',
  Dld = 'DLD',
  Dlr = 'DLR',
  Dlt = 'DLT',
  Dmt = 'DMT',
  Drl = 'DRL',
  Drm = 'DRM',
  Drp = 'DRP',
  Dtl = 'DTL',
  Dtw = 'DTW',
  Dul = 'DUL',
  Ebt = 'EBT',
  Empty = 'EMPTY',
  Emt = 'EMT',
  Exc = 'EXC',
  Fuel = 'FUEL',
  Ful = 'FUL',
  Hct = 'HCT',
  Hlt = 'HLT',
  Hmt = 'HMT',
  Hpl = 'HPL',
  Hpllld = 'HPLLLD',
  Hpllul = 'HPLLUL',
  Htl = 'HTL',
  Ibbt = 'IBBT',
  Ibmt = 'IBMT',
  Idlt = 'IDLT',
  Idmt = 'IDMT',
  Iebt = 'IEBT',
  Iemt = 'IEMT',
  Ihct = 'IHCT',
  Ihlt = 'IHLT',
  Ihmt = 'IHMT',
  Inserv = 'INSERV',
  Lld = 'LLD',
  Llld = 'LLLD',
  Llu = 'LLU',
  Llv = 'LLV',
  Lul = 'LUL',
  Lyl = 'LYL',
  Nbcst = 'NBCST',
  Nbs = 'NBS',
  Pld = 'PLD',
  Pul = 'PUL',
  Rl = 'RL',
  Rpt = 'RPT',
  Rsc = 'RSC',
  Rtp = 'RTP',
  Sap = 'SAP',
  Swpd = 'SWPD',
  Swpdrv = 'SWPDRV',
  Trcb = 'TRCB',
  Trcbkd = 'TRCBKD',
  Trp = 'TRP',
  Ttrp = 'TTRP',
  Ulc = 'ULC',
  Una = 'UNA',
  Wsh = 'WSH',
  Xdl = 'XDL',
  Xdu = 'XDU',
}

export type Shipment = {
  __typename?: 'Shipment'
  actualDelivery?: Maybe<Scalars['DateTime']>
  actualPickup?: Maybe<Scalars['DateTime']>
  appointmentDelivery?: Maybe<Scalars['DateTime']>
  appointmentPickup?: Maybe<Scalars['DateTime']>
  carrier?: Maybe<Carrier>
  carrierTrackingLink?: Maybe<Scalars['String']>
  code: Scalars['String']
  costCenterId: Scalars['Int']
  destination?: Maybe<Location>
  destinationLocation?: Maybe<Location>
  destinationStop?: Maybe<Stop>
  id: Scalars['Int']
  isCriticalCare?: Maybe<Scalars['Boolean']>
  isRush?: Maybe<Scalars['Boolean']>
  load?: Maybe<Load>
  origin?: Maybe<Location>
  originLocation?: Maybe<Location>
  originStop?: Maybe<Stop>
  plannedDeliveryEnd?: Maybe<Scalars['DateTime']>
  plannedDeliveryStart?: Maybe<Scalars['DateTime']>
  plannedPickupEnd?: Maybe<Scalars['DateTime']>
  plannedPickupStart?: Maybe<Scalars['DateTime']>
  primaryReference?: Maybe<CustomerReferenceNumber>
  references: Array<CustomerReferenceNumber>
  requestedDeliveryEnd?: Maybe<Scalars['DateTime']>
  requestedDeliveryStart?: Maybe<Scalars['DateTime']>
  requestedPickupEnd?: Maybe<Scalars['DateTime']>
  requestedPickupStart?: Maybe<Scalars['DateTime']>
  status: ShipmentStatus
}

export type ShipmentPaginatedResponse = {
  __typename?: 'ShipmentPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Shipment>
}

export type ShipmentQuery = {
  actualDeliveryDateRange?: InputMaybe<Array<Scalars['DateTime']>>
  appointmentDeliveryDateRange?: InputMaybe<Array<Scalars['DateTime']>>
  carrierEtaDateRange?: InputMaybe<Array<Scalars['DateTime']>>
  codeEq?: InputMaybe<Scalars['String']>
  codeIcontains?: InputMaybe<Scalars['String']>
  codeIn?: InputMaybe<Array<Scalars['String']>>
  costCenterIdEq?: InputMaybe<Scalars['Int']>
  costCenterIdIn?: InputMaybe<Array<Scalars['Int']>>
  destinationLocaleEq?: InputMaybe<Array<Scalars['String']>>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  isCriticalCareEq?: InputMaybe<Scalars['Boolean']>
  isCriticalCareIsNull?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  localeIn?: InputMaybe<Array<Array<Scalars['String']>>>
  offset?: InputMaybe<Scalars['Int']>
  originLocaleEq?: InputMaybe<Array<Scalars['String']>>
  plannedDeliveryStartDateRange?: InputMaybe<Array<Scalars['DateTime']>>
  referenceEq?: InputMaybe<Array<Scalars['String']>>
  referenceIn?: InputMaybe<Scalars['String']>
  sortByPlannedArrivalStart?: InputMaybe<SortInput>
  statusIn?: InputMaybe<Array<ShipmentStatus>>
}

export enum ShipmentStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  PickedUp = 'PICKED_UP',
  Planned = 'PLANNED',
  Unknown = 'UNKNOWN',
}

export enum SortInput {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE',
}

export enum SourceSystem {
  Radius = 'RADIUS',
  Tm = 'TM',
  Tmw = 'TMW',
}

export type SplitLegResponse =
  | InvalidStopIdx
  | InvalidStopLocation
  | InvalidStopStatus
  | InvalidTmwVersionIdAnton
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | NoCostCenterAssignedToLoad
  | ObjectDoesNotExist
  | SplitLegSuccess
  | TmwValidationError

export type SplitLegSuccess = {
  __typename?: 'SplitLegSuccess'
  load: Load
}

export type Stop = {
  __typename?: 'Stop'
  action: LocationActionType
  appointmentEnd?: Maybe<Scalars['DateTime']>
  appointmentStart?: Maybe<Scalars['DateTime']>
  arrivalTime?: Maybe<Scalars['DateTime']>
  carrierEta?: Maybe<Scalars['DateTime']>
  departureTime?: Maybe<Scalars['DateTime']>
  documents: DocumentResponse
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>
  estimatedDepartureTime?: Maybe<Scalars['DateTime']>
  estimatedDwellTime?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  idx: Scalars['Int']
  isAppointmentValid: Scalars['Boolean']
  location?: Maybe<Location>
  mileageType?: Maybe<Scalars['String']>
  milesToNextStop?: Maybe<Scalars['Float']>
  plannedArrivalEnd?: Maybe<Scalars['DateTime']>
  plannedArrivalStart?: Maybe<Scalars['DateTime']>
  plannedDepartureEnd?: Maybe<Scalars['DateTime']>
  plannedDepartureStart?: Maybe<Scalars['DateTime']>
  removable: Scalars['Boolean']
  scosAction?: Maybe<ScosStopAction>
  scosActionFullName?: Maybe<Scalars['String']>
  timeToNextStop?: Maybe<Scalars['Int']>
  tractorMileage?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  validForSplit: Scalars['Boolean']
}

export type StopAddRequest = {
  appointmentEnd?: InputMaybe<Scalars['DateTime']>
  appointmentStart?: InputMaybe<Scalars['DateTime']>
  idx: Scalars['Int']
  legId: Scalars['Int']
  locationId: Scalars['BigIntLocationID']
  scosAction: ScosStopAction
  tmwVersionId?: InputMaybe<Scalars['BigInt']>
}

export type StopAddResponse =
  | InvalidStopAppointmentEnd
  | InvalidStopAppointmentStart
  | InvalidStopAppointmentTime
  | InvalidStopEvent
  | InvalidStopLocation
  | InvalidStopStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | NoCostCenterAssignedToLoad
  | ObjectDoesNotExist
  | StopAdded
  | TmwValidationError

export type StopAdded = {
  __typename?: 'StopAdded'
  load: Load
  tmwVersionId: Scalars['BigInt']
}

export type StopDeleteResponse =
  | InvalidLegStatus
  | InvalidLegUpdateError
  | InvalidLoadStatus
  | InvalidStopDelete
  | InvalidStopEvent
  | InvalidStopStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | ObjectDoesNotExist
  | StopDeleted
  | TmwValidationError

export type StopDeleted = {
  __typename?: 'StopDeleted'
  load: Load
}

export type StopDuplicateRequest = {
  appointmentEnd?: InputMaybe<Scalars['DateTime']>
  appointmentStart?: InputMaybe<Scalars['DateTime']>
  locationId: Scalars['BigIntLocationID']
  scosAction: ScosStopAction
  stopId: Scalars['Int']
  tmwVersionId: Scalars['BigInt']
}

export type StopReorder = {
  fromIdx: Scalars['Int']
  legId: Scalars['Int']
  tmwVersionId?: InputMaybe<Scalars['BigInt']>
  toIdx: Scalars['Int']
}

export type StopReorderResponse =
  | InvalidLegUpdateError
  | InvalidLoadStatus
  | InvalidStopEvent
  | InvalidStopReorder
  | InvalidStopStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | ObjectDoesNotExist
  | StopReordered
  | TmwValidationError

export type StopReordered = {
  __typename?: 'StopReordered'
  load: Load
}

export type StopUpdate = {
  appointmentEnd?: InputMaybe<Scalars['DateTime']>
  appointmentStart?: InputMaybe<Scalars['DateTime']>
  arrivalTime?: InputMaybe<Scalars['DateTime']>
  departureTime?: InputMaybe<Scalars['DateTime']>
  id: Scalars['Int']
  locationId?: InputMaybe<Scalars['BigIntLocationID']>
  mileageType?: InputMaybe<Scalars['String']>
  scosAction?: InputMaybe<ScosStopAction>
  tmwVersionId?: InputMaybe<Scalars['BigInt']>
  tractorMileage?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['String']>
}

export type StopUpdateResponse =
  | InvalidOdometer
  | InvalidStopAppointmentEnd
  | InvalidStopAppointmentStart
  | InvalidStopAppointmentTime
  | InvalidStopDateTime
  | InvalidStopEvent
  | InvalidStopStatus
  | InvalidStopUpdateField
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | Load
  | ObjectDoesNotExist
  | TmwValidationError

export type Subscription = {
  __typename?: 'Subscription'
  onUserUpdate: User
  subscribeLoads: LoadSubscription
  subscribeUserNotifications: Array<Notification>
}

export type SubscriptionOnUserUpdateArgs = {
  id: Scalars['Int']
}

export type SubscriptionSubscribeLoadsArgs = {
  idIn: Array<Scalars['Int']>
}

export type SubscriptionSubscribeUserNotificationsArgs = {
  userId: Scalars['Int']
}

export type TmwValidationError = ErrorType & {
  __typename?: 'TMWValidationError'
  message: Scalars['String']
}

export type TimeOfWeek = {
  __typename?: 'TimeOfWeek'
  day: DayOfWeek
  time: Scalars['Time']
}

export type TimeOfWeekInput = {
  day: DayOfWeek
  time: Scalars['Time']
}

export type TooManyFavoriteCostCenters = ErrorType & {
  __typename?: 'TooManyFavoriteCostCenters'
  message: Scalars['String']
}

export type TrackingInfo = {
  __typename?: 'TrackingInfo'
  destination: TrackingInfoStop
  georoute: Georoute
  loadReference: Scalars['String']
  origin: TrackingInfoStop
  status: TrackingStatus
  tractorDirectionDegreesAzimuth?: Maybe<Scalars['Float']>
  tractorLocationAddress?: Maybe<Address>
  tractorLocationPoint?: Maybe<GpsPoint>
}

export type TrackingInfoLocation = {
  __typename?: 'TrackingInfoLocation'
  address?: Maybe<Address>
  name?: Maybe<Scalars['String']>
  point?: Maybe<GpsPoint>
  timeZoneCode: Scalars['String']
  timeZoneName: Scalars['String']
}

export type TrackingInfoStop = {
  __typename?: 'TrackingInfoStop'
  arrivalTime?: Maybe<Scalars['DateTime']>
  departureTime?: Maybe<Scalars['DateTime']>
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>
  estimatedDepartureTime?: Maybe<Scalars['DateTime']>
  idx: Scalars['Int']
  location?: Maybe<TrackingInfoLocation>
}

export type TrackingLink = {
  __typename?: 'TrackingLink'
  link: Scalars['String']
}

export type TrackingLinkInput = {
  digest: Scalars['String']
  stopId: Scalars['Int']
  version: TrackingVersion
}

export type TrackingLinkInternalError = ErrorType & {
  __typename?: 'TrackingLinkInternalError'
  message: Scalars['String']
}

export enum TrackingStatus {
  Completed = 'COMPLETED',
  EnRoute = 'EN_ROUTE',
  InProgress = 'IN_PROGRESS',
}

export enum TrackingVersion {
  V1 = 'v1',
}

export type Tractor = {
  __typename?: 'Tractor'
  address?: Maybe<Address>
  costCenters: Array<CostCenter>
  description?: Maybe<Scalars['String']>
  directionDegreesAzimuth?: Maybe<Scalars['Float']>
  id: Scalars['Int']
  lastKnownLocation?: Maybe<GpsPoint>
  name?: Maybe<Scalars['String']>
  overlappingLoadAssignments: Array<Load>
  speedMph?: Maybe<Scalars['Int']>
  status: TractorStatus
  tz?: Maybe<Scalars['String']>
  unitNumber?: Maybe<Scalars['String']>
  vehicleClassName?: Maybe<Scalars['String']>
  vin: Scalars['String']
}

export type TractorOverlappingLoadAssignmentsArgs = {
  legId: Scalars['Int']
}

export type TractorAssignedToLeg = {
  __typename?: 'TractorAssignedToLeg'
  legId: Scalars['Int']
  load: Load
}

export type TractorPaginatedResponse = {
  __typename?: 'TractorPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<Tractor>
}

export type TractorQuery = {
  costCenterCodeIn?: InputMaybe<Array<Scalars['String']>>
  descriptionIlike?: InputMaybe<Scalars['String']>
  descriptionIsNull?: InputMaybe<Scalars['Boolean']>
  fuzzyMatch?: InputMaybe<Scalars['String']>
  idIn?: InputMaybe<Array<Scalars['Int']>>
  limit?: InputMaybe<Scalars['Int']>
  notRetired?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: Array<TractorSortFields>
  unitNumberEq?: InputMaybe<Scalars['String']>
  unitNumberIn?: InputMaybe<Array<Scalars['String']>>
  unitNumberIsNull?: InputMaybe<Scalars['Boolean']>
  vinEq?: InputMaybe<Scalars['String']>
}

export enum TractorSortFields {
  VinAsc = 'VIN_ASC',
  VinDesc = 'VIN_DESC',
}

export enum TractorStatus {
  Avl = 'AVL',
  Out = 'OUT',
  Pln = 'PLN',
  Unk = 'UNK',
  Use = 'USE',
  Vac = 'VAC',
}

export enum TransitMode {
  Air = 'AIR',
  Intermodal = 'INTERMODAL',
  Ltl = 'LTL',
  Ocean = 'OCEAN',
  Parcel = 'PARCEL',
  Rail = 'RAIL',
  Tl = 'TL',
  Unknown = 'UNKNOWN',
}

export type UnableToRetrieveDocuments = ErrorType & {
  __typename?: 'UnableToRetrieveDocuments'
  message: Scalars['String']
}

export type UpdateBackhaulCodeResponse =
  | BackhaulCodeUpdated
  | InvalidLoadStatus
  | InvalidTmwVersionIdClient
  | InvalidTmwVersionIdScos
  | ObjectDoesNotExist
  | TmwValidationError

export type User = {
  __typename?: 'User'
  authCostCenters: Array<CostCenter>
  customer?: Maybe<Customer>
  emails: Array<UserEmail>
  fullname?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isSuperuser: Scalars['Boolean']
  isVerified: Scalars['Boolean']
  phoneNumbers: Array<UserPhoneNumber>
  profilePictureUrl?: Maybe<Scalars['String']>
  role: UserRole
  settings: UserSettings
  uuid: Scalars['String']
}

export type UserAlreadyExists = ErrorType & {
  __typename?: 'UserAlreadyExists'
  message: Scalars['String']
}

export type UserEmail = {
  __typename?: 'UserEmail'
  email: Scalars['String']
  isVerified: Scalars['String']
}

export type UserHasCustomer = ErrorType & {
  __typename?: 'UserHasCustomer'
  message: Scalars['String']
}

export type UserPaginatedResponse = {
  __typename?: 'UserPaginatedResponse'
  meta?: Maybe<PageMeta>
  results: Array<User>
}

export type UserPhoneNumber = {
  __typename?: 'UserPhoneNumber'
  isVerified: Scalars['Boolean']
  phoneNumber: Scalars['String']
}

export type UserQuery = {
  customerIdEq?: InputMaybe<Scalars['Int']>
  fullnameIlike?: InputMaybe<Scalars['String']>
  fullnameIsNull?: InputMaybe<Scalars['Boolean']>
  hasCustomer?: InputMaybe<Scalars['Boolean']>
  isVerifiedEq?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  roleEq?: InputMaybe<UserRole>
  sort?: Array<UserSortFields>
  userEmail?: InputMaybe<Scalars['String']>
}

export type UserResponse =
  | InvalidEmailAddress
  | InvalidPassword
  | InvalidUserCredentials
  | ObjectDoesNotExist
  | User
  | UserAlreadyExists

export enum UserRole {
  Customer = 'CUSTOMER',
  None = 'NONE',
  Operator = 'OPERATOR',
  Sysadmin = 'SYSADMIN',
}

export type UserSettings = {
  __typename?: 'UserSettings'
  favoriteCostCenters: Array<CostCenter>
  homePage?: Maybe<HomePage>
  updatedAt?: Maybe<Scalars['DateTime']>
  userId: Scalars['Int']
}

export type UserSettingsResponse =
  | ObjectDoesNotExist
  | TooManyFavoriteCostCenters
  | UserSettings

export type UserSettingsUpdate = {
  favoriteCostCenters?: InputMaybe<Array<Scalars['Int']>>
  homePage?: InputMaybe<HomePage>
  userId: Scalars['Int']
}

export enum UserSortFields {
  FullnameAsc = 'FULLNAME_ASC',
  FullnameDesc = 'FULLNAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
}

export type ValidTrackingLink = {
  __typename?: 'ValidTrackingLink'
  valid: Scalars['Boolean']
}

export type ValidateTrackingLinkResponse =
  | InvalidTrackingLink
  | TrackingLinkInternalError
  | ValidTrackingLink

export type Worker = {
  __typename?: 'Worker'
  email?: Maybe<Scalars['String']>
  employeeId: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}
